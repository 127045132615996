.glitch {
  width: 100%;
  height: 100%;
  min-width: 200px;
  min-height: 200px;
  max-width: 500px;
  max-height: 500px;
  background-size: cover;
  transition: transform 0.2s ease;
}

.glitch:hover {
  transform: scale(1.04);
}

/* larger screens */
@media (min-width: 800px) {
  .glitch {
    width: 500px;
    height: 500px;
  }
}

/* smaller screens */
@media (max-width: 799px) {
  .glitch {
    width: 250px;
    height: 250px;
  }
}
