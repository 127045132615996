@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.project-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 700px;
  padding: 16px;
  margin-bottom: 30px;
  border-radius: 30px;
  background-color: #313945;
  color: #fff;
  text-align: left;
  /* initially invisible, then fade into place */
  opacity: 0;
  transform: translateY(20px);
}

.project-card.visible {
  opacity: 1;
  transform: translateY(0);
  animation: fadeIn 0.6s ease forwards;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-card-container {
  border-radius: 30px;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s;
}

.project-card-container:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.project-card-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.project-logo {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 16px;
}

.project-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-title {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
  color: #ffffff;
}

.project-date {
  font-size: 0.9em;
  color: #b3b3b3;
  margin: 0;
}

.project-description {
  font-size: 0.9em;
  color: #b3b3b3;
  margin-top: 12px;
}

a {
  text-decoration: none;
  color: inherit;
}

h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin: 8px 0;
}

p {
  font-size: 0.9em;
  color: #b3b3b3;
}
